/**
 * We dont want the keypress event to trigger an API call _every_ keystroke, so we debounce it to a second or so
 */
export const debounce = (callback: (...args: unknown[]) => void, time: number, immediateCallback?: () => void): (...args: unknown[]) => void => {
  let interval: number | undefined;
  return (...args: unknown[]) => {
    if (immediateCallback) {
      immediateCallback();
    }

    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = undefined;
      callback(...args);
    }, time);
  };
};
