import { dqs, debounce, forEachElement } from './util';
import { FetcherFactory } from './factory/FetcherFactory';
import { ApiConfig, Fetcher } from './domain/interface';
import { DisplayableResult } from './domain/class';
import { API_CONFIGURATION } from './config/API_CONFIGURATION';

const endpoint = 'https://floris.amsterdam/cachedview';

const check = async (url: string, serviceName: string, serviceConfig: ApiConfig) => {
  const widget = dqs(serviceConfig.SELECTOR);
  const status = widget.querySelector('.status') as HTMLElement;
  const link = [...widget.querySelectorAll('.url')];
  const message = widget.querySelector('.msg') as HTMLElement;

  const fetcher: Fetcher = await FetcherFactory(serviceConfig.TYPE);

  fetcher.fetch(endpoint, url, serviceName, serviceConfig)
    .then(function(res) {
      widget.classList.remove('loading', 'good', 'bad');
      widget.classList.add('good');
      link.forEach((element) => {
        (element as HTMLAnchorElement).href = res.url as string;
      });
      status.innerText = `${res.statusCode}`;

      if (res.message) {
        message.innerText = res.message;
      }

      if (res.message_url) {
        (message as HTMLAnchorElement).href = res.message_url;
      }
    })
    .catch(function(res: DisplayableResult) {
      widget.classList.remove('loading', 'good', 'bad');
      widget.classList.add('bad');

      link.forEach((element) => {
        element.removeAttribute('href');
      });

      status.innerText = `${res.statusCode}`;

      if (res.message) {
        message.innerText = res.message;
      }

      if (res.message_url) {
        (message as HTMLAnchorElement).href = res.message_url;

        link.forEach((element) => {
          (element as HTMLAnchorElement).href = res.message_url || '';
        });
      }
    });
};

// Debounce the keydown event and pass the typed URL to the fetchers
const debouncedSearch = debounce(() => {
  forEachElement('.widget', (element) => {
    element.classList.remove('loading', 'good', 'bad');
  });

  dqs('.screenshot_image').classList.add('hidden');
  dqs('.screenshot').classList.remove('expanded');

  forEachElement('.widget:not(.disabled):not(.screenshot) .msg', (element) => {
    element.innerText = '';
  });

  forEachElement('.widget:not(.disabled):not(.screenshot) .msg', (element) => {
    element.removeAttribute('href');
  });

  forEachElement('.status', (element) => {
    element.innerText = '';
  });

  const input = dqs<HTMLInputElement>('#putin').value;

  if (!input) {
    return;
  }

  if (!input.match(/(.+)\.(.+)/gi)) {
    // Input doesn't seem to be a URL (i.e. doesn't contain a `domain.tld` pattern)
    dqs('#validity').innerText = 'This doesn\'t look like a URL 🤔';
    return;
  } else {
    dqs('#validity').innerText = '';
  }

  forEachElement('.widget:not(.disabled):not(.screenshot)', (element) => {
    element.classList.add('loading');
  });

  // Iterate over the list of caching/archiving services and run Check() for each of them.
  Object.entries(API_CONFIGURATION).forEach((service) => {
    const key = service[0];
    const value = service[1];
    check(input, key, value);
  });
}, 1500, () => {
  // Rev up the loading spinners to indicate that the search is being processed
  forEachElement('.widget:not(.disabled):not(.screenshot)', (element) => {
    element.classList.remove('loading', 'good', 'bad');
    element.classList.add('loading');
  });

  forEachElement('.widget:not(.disabled):not(.screenshot) .msg', (element) => {
    element.innerText = '';
  });

  forEachElement('.widget:not(.disabled):not(.screenshot) .msg', (element) => {
    element.removeAttribute('href');
  });

  forEachElement('.status', (element) => {
    element.innerText = '';
  });
});

const onload = function() {
  if (location.hash) {
    dqs<HTMLInputElement>('#putin').value = decodeURIComponent(location.hash.split('#')[1] || '');
    debouncedSearch();
  }

  dqs('#putin').addEventListener('paste', debouncedSearch);
  dqs('#putin').addEventListener('keyup', debouncedSearch);
  dqs('#putin').addEventListener('keyup', (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      debouncedSearch();
    }
  });

  dqs('.screenshot').addEventListener('click', () => {
    const input = dqs<HTMLInputElement>('#putin').value;

    if (!input) {
      return;
    }

    dqs('.screenshot').classList.add('expanded');
    dqs('.screenshot_image').classList.add('loading');
    dqs('.screenshot_image').classList.remove('hidden');
    dqs('.screenshot_image').classList.remove('loaded');
    dqs('.screenshot_image').setAttribute('src', 'img/loader.svg');
    const src = `${endpoint}/screenshot/${encodeURIComponent(input)}`;

    fetch(src).then((response) => response.text()).then((data) => {
      dqs('.screenshot_image').classList.add('loaded');
      dqs('.screenshot_image').classList.remove('loading');
      dqs('.screenshot_image').setAttribute('src', `data:image/png;base64,${data}`);

      dqs<HTMLAnchorElement>('.screenshot_download').href = `data:image/png;base64,${data}`;
      dqs<HTMLAnchorElement>('.screenshot_download').download = 'screenshot.png';
    });
  });
};

window.addEventListener('load', onload, false);
