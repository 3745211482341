import { ApiConfig } from '../domain/interface/ApiConfig';
import { DisplayableResult } from '../domain/class/DisplayableResult';
import { Fetcher } from '../domain/interface/Fetcher';
import { PingResponse } from '../domain/class/PingResponse';

export class MementoFetcher implements Fetcher {
  fetch(endpoint: string, url: string, serviceName: string, serviceConfig: ApiConfig): Promise<DisplayableResult> {
    return new Promise((resolve, reject) => {
      const target = `${endpoint}/${serviceName}/${encodeURIComponent(url)}`;

      fetch(target).then((data) => data.json()).then((data) => {
        const castData = data as PingResponse;

        if (castData.statusCode === 404) {
          switch (serviceName) {
            case 'ARCHIVEAPI': {
              reject(new DisplayableResult({
                statusCode: castData.statusCode,
                message: 'Click here to cache this page now',
                message_url: `http://web.archive.org/save/${url}`,
              }))
              break;
            }
            case 'PERMACC': {
              reject(new DisplayableResult({
                statusCode: castData.statusCode,
                message: 'Click here to cache this page now\n(Registration required)',
                message_url: `https://perma.cc/manage/create?url=${url}`,
              }))
              break;
            }
            default: {
              reject(new DisplayableResult({
                statusCode: castData.statusCode,
                url: castData.urlUsed,
                message: castData.error || 'No archived version available'
              }));
              break;
            }
          }
        }

        if (!castData.error || castData.statusCode === 200) {
          resolve(new DisplayableResult({
            statusCode: castData.statusCode,
            url: castData.urlUsed,
          }));
        } else {
          reject(new DisplayableResult({
            statusCode: castData.statusCode ? !isNaN(castData.statusCode) ? castData.statusCode : 500 : 500,
            message: castData.error
          }));
        }
      }).catch((error) => {
        console.warn('Error on ping:');
        console.error(error);
        reject(new DisplayableResult({
          statusCode: 408,
          message: 'Request timed out, click here to retry',
          message_url: `${endpoint}/${serviceName}/${encodeURIComponent(url)}`
        }))
      });
    });
  }
}
