/**
 * Represents a result from a ping or memento, to be displayed in a widget
 */
export class DisplayableResult {
  url?: string;
  message?: string;
  message_url?: string;
  statusCode?: number;

  constructor({url, message, message_url, statusCode}: {url?: string, message?: string; message_url?: string; statusCode?: number}) {
    this.url = url;
    this.message = message;
    this.message_url = message_url;
    this.statusCode = statusCode;
  }
}