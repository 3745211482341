import { ApiConfig } from '../domain/interface/ApiConfig';
import { DisplayableResult } from '../domain/class/DisplayableResult';
import { Fetcher } from '../domain/interface/Fetcher';
import { PingResponse } from '../domain/class/PingResponse';

export class PingFetcher implements Fetcher {
  fetch(endpoint: string, url: string, serviceName: string, serviceConfig: ApiConfig): Promise<DisplayableResult> {
    return new Promise((resolve, reject) => {
      const target = `${endpoint}/${serviceName}/${encodeURIComponent(url)}`;

      fetch(target).then((data) => data.json()).then((data) => {
        const castData = data as PingResponse;
        if (castData.statusCode === 404) {
          reject(new DisplayableResult({
            statusCode: castData.statusCode,
            url: castData.urlUsed,
            message: castData.error || 'No archived version available'
          }));
        }

        if (!castData.error || castData.statusCode === 200) {
          resolve(new DisplayableResult({
            statusCode: castData.statusCode,
            url: castData.urlUsed,
          }));
        } else {
          reject(new DisplayableResult({
            statusCode: isNaN(castData.statusCode as number) ? 500 : castData.statusCode as number,
            message: castData.error
          }));
        }
      }).catch((error) => {
        console.warn('Error on ping:');
        console.error(error);
        reject(new DisplayableResult({
          statusCode: 408,
          message: 'Request timed out, click here to retry',
          message_url: `${endpoint}/${serviceName}/${encodeURIComponent(url)}`
        }))
      });
    });
  }
}
