import { API_TYPE } from '../domain/enum/API_TYPE';
import { MementoFetcher } from '../fetchers/MementoFetcher';
import { PingFetcher } from '../fetchers/PingFetcher';
import { Fetcher } from '../domain/interface/Fetcher';

export const FetcherFactory = async (type: API_TYPE): Promise<Fetcher> => {
  switch (type) {
    case API_TYPE.MEMENTO: {
      return new MementoFetcher();
    }
    case API_TYPE.PING: {
      return new PingFetcher();
    }
    default: {
      return new PingFetcher();
    }
  }
};
