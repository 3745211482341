import { API_TYPE } from '../domain/enum/API_TYPE';

export const API_CONFIGURATION = {
  WEBCACHE: {
    TEMPLATE: 'https://webcache.googleusercontent.com/search?q=cache:%%URL%%',
    TYPE: API_TYPE.PING,
    SELECTOR: '.widget.webcache',
  },
  ARCHIVEAPI: {
    TEMPLATE: 'http://web.archive.org/web/timemap/link/%%URL%%',
    TYPE: API_TYPE.MEMENTO,
    SELECTOR: '.widget.archive',
  },
  // CORAL: {
  //   TEMPLATE: 'https://redirect.nyud.net/?url=%%URL%%',
  //   TYPE: API_TYPE.PING,
  // },
  // MEGALODON: {
  //   TEMPLATE: '',
  //   TYPE: API_TYPE.PING,
  // },
  LIVEVERSION: {
    TEMPLATE: '%%URL%%',
    TYPE: API_TYPE.PING,
    SELECTOR: '.widget.live',
  },
  PERMACC: {
    TEMPLATE: 'https://perma.cc/timemap/link/%%URL%%',
    TYPE: API_TYPE.MEMENTO,
    SELECTOR: '.widget.permacc',
  },
  // OLD_PERMACC: {
  //   TEMPLATE: 'https://archive.org/advancedsearch.php',
  //   TYPE: API_TYPE.PERMACC,
  // },
  ARCHIVETODAY: {
    TEMPLATE: 'https://archive.is/timemap/%%URL%%',
    TYPE: API_TYPE.MEMENTO,
    SELECTOR: '.widget.today',
  },
  LIBRARYOFCONGRESS: {
    TEMPLATE: 'https://webarchive.loc.gov/all/timemap/link/%%URL%%',
    TYPE: API_TYPE.MEMENTO,
    SELECTOR: '.widget.libraryofcongress',
  },
  // UKWA: {
  //   TEMPLATE: 'https://www.webarchive.org.uk/wayback/archive/timemap/link/%%URL%%',
  //   TYPE: API_TYPE.MEMENTO,
  // },
  // ARQUIVO: {
  //   TEMPLATE: 'https://arquivo.pt/wayback/timemap/*/%%URL%%',
  //   TYPE: API_TYPE.MEMENTO,
  // },
}
